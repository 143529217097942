<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="bar">
        <div class="title">购药信息</div>
        <div v-if="items.length === 0">
          <div class="rb1" @click="addNode()">
            <img src="@/assets/add.png" class="icon" />
            <div class="text">添加</div>
          </div>
          <div class="rb" @click="submit()">
            <img src="@/assets/tj.png" class="icon" />
            <div class="text">提交</div>
          </div>
        </div>
        <div v-if="items.length > 0">
          <div class="rb2" @click="quit()">
            <img src="@/assets/gb.png" class="icon" />
            <div class="text">关闭</div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="items">
          <div v-if="items.length > 0">
            <div class="item" v-for="item1 in items" :key="item1.id">
              <div class="box">
                <img :src="getImgUrl(item1.medicine_picture)" class="imgbox" />
              </div>
              <div class="box">
                <div class="name">{{ item1.medicine_code }}</div>
                <div class="unames">数量：{{ item1.medicine_count }}</div>
              </div>
            </div>
          </div>
          <div v-if="items.length === 0">
            <div class="item" v-for="(item,index) in pharmacyinfo" :key="index">
              <div class="box">
                <img :src="pharmacyinfo[index].imgurl" @click="photograph(index)" class="imgbox" />
                <!--<img src="@/assets/sweepcode.png"  v-if="pharmacyinfo[index].false" class="imgbox" />-->
              </div>
              <div class="box">
                <div class="name">{{pharmacyinfo[index].barcode}}</div>
                <div class="unames"  v-if="pharmacyinfo[index].flag">
                  <div class="text tt">处方药:</div>
                  <div v-if="pharmacyinfo[index].cfflag == false"><img src="@/assets/kgoff.png" @click="onCf(index)" class="iconkg" /></div>
                  <div v-if="pharmacyinfo[index].cfflag == true"><img src="@/assets/kgon.png" @click="onCf(index)" class="iconkg" /></div>
                  <div class="text tt">{{pharmacyinfo[index].cftxtflag}}</div>

                </div>
                <div class="unames"  v-if="pharmacyinfo[index].flag">
                  <div class="text tt">疫情药:</div>
                  <div v-if="pharmacyinfo[index].yqflag == false"><img src="@/assets/kgoff.png"  @click="onYq(index)" class="iconkg" /></div>
                  <div v-if="pharmacyinfo[index].yqflag == true"><img src="@/assets/kgon.png"  @click="onYq(index)" class="iconkg" /></div>
                  <div class="text tt">{{pharmacyinfo[index].yqtxtflag}}</div>
                </div>
                <div class="unames"  v-if="pharmacyinfo[index].flag">
                  <img src="@/assets/cutnum.png" @click="cutnum(index)" class="icon" />
                  <div class="text">{{pharmacyinfo[index].articlenum}}</div>
                  <img src="@/assets/addnum.png" @click="addnum(index)" class="arrow" />
                  <!--<img src="@/assets/photograph.png" @click="photograph(index)" class="photo" />-->
                </div>
              </div>
              <div class="box">
                <img src="@/assets/del.png" @click="deleteNode(index)" class="del active" />
                <div class="view active" @click="sweepCode(index)">扫码</div>
              </div>
            </div>
          </div>
        </div>       
      </div>
      <!--<div class="tj">
          <div v-if="items.length === 0">
            <div class="button primary mt" @click="submit()">提交</div>
          </div>
          <div v-if="items.length > 0">
            <div class="button primary mt" @click="quit()">关闭</div>
          </div>
      </div>-->
    </div>
  </div>
</template>

<script>
import { get, map } from 'lodash-es'
import { Dialog, Toast } from 'vant'
import { isEmpty, isTel } from '@/utils';
import qs from 'qs'

export default {
  async created() {
    let { token } = this.$route.query;
    this.token = token;
    let config = (
      await this.axios.post(
        '/ctid/testService/jsapisign',
        qs.stringify({
          url: location.href
        })
      )
    ).data.data;
    window.wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: config.appid, // 必填，公众号的唯一标识
      timestamp: config.timestamp, // 必填，生成签名的时间戳 ${wxConfig.timestamp}
      nonceStr: config.nonce_str, // 必填，生成签名的随机串 ${wxConfig.nonceStr}
      signature: config.signature, // 必填，签名，见附录1 ${wxConfig.signature}
      jsApiList: [// 必填，需要使用的JS接口列表，所有JS接口列表见附录2
              'checkJsApi',
							'scanQRCode',
							'getLocation', 
							'chooseImage',//拍照或从手机相册中选图接口
							'previewImage',//预览图片接口
							'uploadImage',//上传图片接口
							'downloadImage',//下载图片接口
							'getLocalImgData'
        ] 
    });

    let { data } = await this.axios.post(
      '/ctid/enterpriseService/getMedicinesInfo',
      qs.stringify({
        token: this.token
      })
    );

    if(data.code=='1'){
      var jsonObj = JSON.parse(data.data);
      this.items = jsonObj;
    }
    

  },
  data () {
    return {
      state: false,
      itemflag: false,
      token:"",
      items: [],
      pharmacyinfo:[
        {barcode: '', articlenum: 1, flag: false, sflag: false, cfflag: false, cftxtflag: '否', yqflag: false, yqtxtflag: '否', imgname: '', imgurl: '/img/sweepcode.png'}
      ]
    }
  },
  methods: {   
    addNode(){
      this.pharmacyinfo.unshift({barcode: '', articlenum: 1, flag: false, sflag: false, cfflag: false, cftxtflag: '否', yqflag: false, yqtxtflag: '否', imgname: '', imgurl: '/img/sweepcode.png'})
     
    },
    deleteNode:function(i) {
      //alert("删除index为:"+i);
      Dialog.confirm({
        message: '是否删除?'
      }).then(async () => {
        this.pharmacyinfo.splice(i,1);  //删除index为i,位置的数组元素
      });
      
    },
    onCf(i) {
      this.pharmacyinfo[i].cfflag=!this.pharmacyinfo[i].cfflag;
      if(this.pharmacyinfo[i].cfflag){
        this.pharmacyinfo[i].cftxtflag='是';
      }else{
        this.pharmacyinfo[i].cftxtflag='否';
      }     
    },
    onYq(i) {
      this.pharmacyinfo[i].yqflag=!this.pharmacyinfo[i].yqflag;
      if(this.pharmacyinfo[i].yqflag){
        this.pharmacyinfo[i].yqtxtflag='是';
      }else{
        this.pharmacyinfo[i].yqtxtflag='否';
      } 
    },   
    async submit(){
      if(this.state){
        return false;
      }
      this.state = true;
      var tflag = true;

      if (isEmpty(this.token)) {
        Toast('token信息不全!');
        this.state = false;
        return;
      }

      for(var i = 0; i < this.pharmacyinfo.length; i++) {
        if (this.pharmacyinfo[i].barcode.length ==0){
          Toast('条码信息不能为空！');
          tflag = false;
          break ;
        }else if (this.pharmacyinfo[i].barcode.length != 13){
          tflag = false;
          Toast('条码信息不是13位！');
          break ;
        }else if (this.pharmacyinfo[i].imgname.length ==0){
          tflag = false;
          Toast('药品图片不能为空！');
          break ;
        }
        
      }

      if(tflag){
        for(var j = 0; j < this.pharmacyinfo.length; j++) {
          let { data } = await this.axios.post(
            '/ctid/enterpriseService/setMedicinesInfo',
            qs.stringify({
              token: this.token,
              barcode: this.pharmacyinfo[j].barcode,
              articlenum: this.pharmacyinfo[j].articlenum,
              imgname: this.pharmacyinfo[j].imgname

            })
          );       
          //alert("条码：" + this.pharmacyinfo[j].barcode + "，数量：" +this.pharmacyinfo[j].articlenum + "，图片名：" +this.pharmacyinfo[j].imgname);
        }
      
        let { data } = await this.axios.post(
          '/ctid/enterpriseService/getMedicinesInfo',
          qs.stringify({
            token: this.token
          })
        );

        if(data.code=='1'){
          var jsonObj = JSON.parse(data.data);
          this.items = jsonObj;
        }
        /*let { data } = await this.axios.post(
          '/ctid/enterpriseService/getMedicinesInfo',
          qs.stringify({
            token: this.token
          })
        );       
        if(data.code=='1'){
          Toast(data.msg);
          this.items = data.data;
        }*/


      } 
      this.state = false;     
      
    },
    sweepCode:function(i) {
      window.wx.scanQRCode({
        needResult: 1,
        scanType: ['qrCode', 'barCode'],
        success: res => {
          let result = res.resultStr;
          if (result.includes(',')) {
              result = result.split(',')[1];
              this.pharmacyinfo[i].barcode = result;
              this.pharmacyinfo[i].flag = true;
          }
        },
        error: res => {
          Dialog({ message: res });
        }
      });
         
    },
    addnum(i){
      this.pharmacyinfo[i].articlenum = this.pharmacyinfo[i].articlenum + 1;
    },
    cutnum(i){
      if(this.pharmacyinfo[i].articlenum>1){
        this.pharmacyinfo[i].articlenum = this.pharmacyinfo[i].articlenum - 1;
      }   
    },
    photograph(i){
      //选择照片
      window.wx.chooseImage({
        count: 1, //张数， 默认9
        sizeType: ['compressed'], //建议压缩图
        sourceType: ['camera'], // 来源是相册(album)、相机(camera)
        success: res => {
          var localIds = res.localIds.toString();

          //上传至微信服务器
          window.wx.uploadImage({
            localId: localIds, // 需要上传的图片的本地ID，由chooseImage接口获得
            success: res => {
              var serverId = res.serverId; // 返回图片的服务器端ID          
              this.uploadImg(serverId,i);

            },
            fail: function() {
              Toast("上传图片异常");
            }
          });

        },
        fail: function() {
          Toast("选择图片异常");
        }
      });  
      
    },
    async uploadImg(serverId,i) {
      let { data } = await this.axios.post(
        '/ctid/enterpriseService/drugsDownFile',
        qs.stringify({
          serverid: serverId
        })
      );
      
      if(data.code=='1'){
        Toast(data.msg);
        //Dialog({ message: data.msg });
        this.pharmacyinfo[i].imgurl = "http://tmp.image.ywkj.online/"+data.file_name+".jpg";
        this.pharmacyinfo[i].imgname = data.file_name;
      }
      else{
        //Dialog({ message: data.msg });
        Toast(data.msg);
      }
      
    },
    getImgUrl(icon){
      return "http://tmp.image.ywkj.online/"+icon+".jpg";
    },
    quit(){
      window.WeixinJSBridge.invoke('closeWindow',{},function(res){});
    },

  }
}
</script>

<style lang="less" scoped>
.bar {
  margin: 48px 40px;
  overflow: hidden;
  .title {
    line-height: 60px;
    color: #fff;
    font-size: 44px;
    float: left;
  }
  .rb {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #7456ff;
    border-radius: 38px;
    margin-right: 8px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  .rb1 {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #7456ff;
    border-radius: 38px;
    .icon {
      width: 44.1px;
      height: 44.1px;
      margin: 10px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  .rb2 {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #7456ff;
    border-radius: 38px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
}
.content {
  padding: 0px;
  margin-top: 40px;
}
.items {
  overflow: hidden;
  padding-bottom: 32px;
  min-height: 616px;
  .item {
    margin: 0px 26px;
    padding: 24px 14px 22px;
    overflow: hidden;
    display: flex;
    border-bottom: 2px solid #eee;
  }
  .box {
    flex: 1;
    overflow: hidden;
    .icon {
      width: 40px;
      height: 40px;
      margin: 5px 0 5px 0px;
      display: block;
      float: left;
    }
    .arrow {
      display: block;
      width: 40px;
      height: 40px;
      float: left;
      margin-left: 5px;
      line-height: 40px;
      margin-top: 5px;
    }
    .photo {
      display: block;
      width: 50px;
      height: 50px;
      float: left;
      margin-left: 30px;
      line-height: 50px;
      margin-top: 0px;
    }
     
    .imgbox {
      width: 180px;
      height: 180px;
      margin: 10px 0px;
      display: block;
      float: left;
    }
    .text {
      font-size: 32px;
      float: left;
      margin-left: 5px;
      line-height: 44px;
      margin-top: 5px;
      &.tt {
        font-size: 26px;
      }
    }
    .name {
      height: 50px;
      line-height: 50px;
      overflow: hidden;
      font-size: 28px;
      margin-top: 0px;
      margin-left: 2px;
    }
    .iconkg {
      display: block;
      width: 70px;
      float: left;
      margin-left: 5px;
      line-height: 40px;
      margin-top: 0px;
    }
    .unames {
      line-height: 40px;
      font-size: 28px;
      margin-top: 0px;
      display: block;
      width: 200px;

    }
    .del {
      width: 55px;
      height: 55px;
      display: block;
      float: right;
      margin-top: 70px;
    }
    .tj {
      width: 660px;
      overflow: hidden;
      margin: 0px auto;
      padding: 30px 0px 20px 0px;
    }
    .view {
      margin-top: 70px;
      width: 110px;
      height: 55px;
      border: 2px solid rgba(0, 29, 255, 1);
      border-radius: 44px;
      text-align: center;
      line-height: 55px;
      color: rgba(0, 29, 255, 1);
      font-size: 26px;
      float: right;
      margin-right: 10px;
    }
  }
}
.empty {
  text-align: center;
  margin-top: 212px;
  img {
    width: 150px;
  }
  .text {
    line-height: 40px;
    font-size: 28px;
    margin-top: 20px;
  }
}
</style>
